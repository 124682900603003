import React, { useState } from "react"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import Svg from "../ui/svg"
import Title from "../ui/title"
import {useToast} from "../toast/useToast"

/**
 * @title Contact Form Component
 * @param {String} text Testo del secondo titolo
 * @param {Boolean} noTitle Non mostra il primo titolo
 * @returns {React.Component} Section Html Element
 * @description Componente che ritorna un Section HTML con all'interno il form di contatto
 * del sito. All'interno del componente viene utilizzato il custom Hook "useToast" per le 
 * notifiche di feedback dell'invio della mail ed è presente parte dell'integrazione a 
 * Google Recaptcha (generazione del token).
 */

const ContactForm = ({text, noTitle}) => {
    
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [subject, setSubject] = useState("")
    const [telephone, setTelephone] = useState("")
    const [body, setBody] = useState("")
    const [readPolicy, setReadPolicy] = useState(false)
    const [loading, setLoading] = useState(false)
    const { executeRecaptcha } = useGoogleReCaptcha()
    // const form_action = ""
    const form_action = `${process.env.GATSBY_EMAIL_FORM_ACTION}`
    const id_section = "Contattaci!"
    // const style_container = "grid grid-cols-1 md:grid-cols-2 place-items-center md:p-6 mx-auto"
    const style_container = "p-5 sm:p-10 md:p-20 mx-auto "
    const style_input = "bg-gray-100 block appearance-none placeholder-gray-500 placeholder-opacity-100 border border-light-blue-400 rounded-md w-full py-3 px-4 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-light-blue-300 hover:scale-105 duration-300"
    const style_txt_area = "bg-gray-100 resize-none border rounded-md block appearance-none placeholder-gray-500 placeholder-opacity-100 border border-light-blue-400 rounded-md w-full py-3 px-4 text-gray-700 leading-5 focus:outline-none focus:ring-2 focus:ring-light-blue-300 h-56 hover:scale-105 duration-300"
    const style_send_btn = (readPolicy ? "animate-bounce dark:hover:bg-gray-900 hover:bg-gray-400 hover:text-white dark:text-white text-secondary-600 " : "text-secondary-500 " )+ "w-full dark:bg-gray-700 bg-gray-300 font-bold py-3 px-4 rounded-xl text-2xl duration-500"
    
    const toast = useToast()
    const showToast = (text, error) => toast.open(text, error)
    const clearState = () =>{
        setName("")
        setSurname("")
        setEmail("")
        setSubject("")
        setTelephone("")
        setBody("")
    }
    const manageSendEmail = sended =>{
        if(sended){
            showToast("Email correttamente inviata!", false)
            clearState()
        }else
            showToast("Errore durante l'invio: si prega di riprovare o di contattarci telefonicamente ", true)
        setLoading(false)
    }

    async function manageSubmit(event){
        event.preventDefault()
        setLoading(true)
        let res = false
        if (!executeRecaptcha) {
            return
        }
        // await setTokenReCap(res_token) //--> grab the generated token by the reCAPTCHA
        try{
            const res_token = await executeRecaptcha('homepage')
            let result = await fetch(form_action,{
                method: "POST",
                body: JSON.stringify({
                    token_recaptcha: res_token,
                    name: name,
                    surname: surname,
                    email: email,
                    subject: subject,
                    telephone: telephone,
                    body: body,
                })
            })
            let result_json = await result.json()
            if(result_json)
                res = true
        }catch(err){
            console.error(err)
        }
        manageSendEmail(res)
    }

    return(
        <section className="group w-full" id={id_section} title={id_section}>

            {
                noTitle
                ?
                null
                :
                <div data-sal="slide-up" data-sal-duration="900"
                className="mt-20 text-center">
                    <Title size="xl" text="Lavoriamo assieme!" bg="bg-primary-500" center skew />
                </div>
            }
            {
                noTitle
                ?
                null
                :
                <div data-sal="slide-down" data-sal-duration="800"
                className=" w-full mt-10 md:mt-16 mb-4 md:mb-14 text-4xl sm:text-5xl md:text-6xl lg:text-7xl text-primary-500 text-center">
                    {text ? text : "Let's build something great!"}
                </div>
            }

            <div className={style_container + " flex flex-col lg:flex-row"} id="contact-form">
                <Svg.Email title="Contattaci!" className="lg:relative lg:w-2/3 group-hover:-hue-rotate-15 animate-flip duration-300 "/>
                {/* <div className="w-full p-8 lg:p-10" > */}
                {/* </div> */}
                <form data-sal="slide-left" data-sal-duration="900"
                method="post" action={form_action}
                className="lg:w-4/6 md:p-4 p-3 bg-secondary-600 dark:bg-white rounded-3xl z-10 lg:-ml-36"
                onSubmit={(e) => {
                    manageSubmit(e)
                }}>
                    <p className="p-3 font-bold dark:text-secondary-600 text-secondary-100 text-base md:text-lg">
                        Contattaci per approfondimenti, informazioni o preventivi
                    </p>
                    <div className="p-3">
                        <input name="name" className={style_input} type="text" placeholder="Nome" onChange={e=>setName(e.target.value)} value={name} />
                    </div>
                    <div className="p-3">
                        <input name="surname" className={style_input} type="text" placeholder="Cognome" onChange={e=>setSurname(e.target.value)} value={surname}/>
                    </div>
                    <div className="p-3">
                        <input name="email"
                            className={style_input} type="email" placeholder="Email*" required 
                            title="Inserire un'email valida" onChange={e=>setEmail(e.target.value)} value={email}/>
                    </div>
                    <div className="p-3">
                        <input name="subject" className={style_input} type="text" placeholder="Oggetto*" required onChange={e=>setSubject(e.target.value)} value={subject}/>
                    </div>
                    <div className="p-3">
                        <input name="telephone" className={style_input} type="text" placeholder="Telefono" onChange={e=>setTelephone(e.target.value)} value={telephone}/>
                    </div>
                    <div className="p-3">
                        <textarea name="body" className={style_txt_area} placeholder="Messaggio*" required onChange={e=>setBody(e.target.value)} value={body} />
                    </div>
                    <div className="p-3">
                        <input type="checkbox" name="policy"
                            onClick={()=>{
                                let tmp_read = readPolicy
                                setReadPolicy(!tmp_read)
                        }}
                        onChange={e => {}}
                        checked={readPolicy} required />
                        <span className="pl-5 text-secondary-100 dark:text-secondary-600">
                            <span>Ho letto </span>
                            <a href={`${process.env.GATSBY_IUBENDA_PRIVACY_POLICY_URL}`}
                            className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe
                            text-blue-500 dark:text-blue-600 underline hover:underline-offset-4
                            hover:text-blue-300 dark:hover:text-blue-400 duration-300 "
                            title="Privacy Policy ">
                                l'informativa sulla privacy 
                            </a>
                            <span> ai sensi del GDPR 679/2016 e autorizzo il trattamento dei miei dati personali</span>
                        </span>
                    </div>
                    <div className="p-3 pt-4">
                        <button className={style_send_btn + " flex justify-center items-center"} disabled={!readPolicy}>
                            {
                                loading ?
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-600 dark:text-blue-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                :
                                null
                            }
                            Invia il tuo messaggio
                        </button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default ContactForm