import React from "react"
import ContactForm from "../components/common/contact-form"
import OpenSection from "../components/layouts/open-section"
import costanti from "../properties/costanti"
import Seo from "../components/seo"
import GoogleMap from "../components/contacts/google-map"
import LineDivider from "../components/ui/divider/line-separator"

/**
 * @title ContactPage
 * @returns {React.Component} Component Page
 * @description Pagina Html con i contatti del sito.
 * E' presente anche il tag SEO, componente ad uso esclusivo per le pagine.
 */

export default function contact() {
    
    const id_where = "Dove-trovarci"
    const id_info = "Orari-e-Contatti"
    const section_style_no_color = `flex flex-col flex-grow items-center text-justify text-xl px-8
    relative pt-20 pb-0 md:pb-10 md:pt-48 lg:pt-36 xl:pt-40 justify-center `
    const section_style = section_style_no_color + `text-secondary-600 dark:text-secondary-100 `
    
    return(
        <>
            
            <Seo title="Contatti" metaTitle="Lavoriamo assieme!" description="Contatta il nostro team, saremo al tuo fianco per ogni necessità!" />

            <div className={`flex flex-col items-center justify-center 
            text-secondary-600 dark:text-secondary-100 `+ costanti.theme.color.img.inverted}>
                <OpenSection
                leftText={"Saremo al tuo fianco per ogni necessità"}
                leftSubChild={
                    <div data-sal="slide-down" data-sal-duration="1000"
                    className="text-white hover:text-emerald-300 font-bold pt-5 pb-10 md:pb-0 cursor-pointer"
                    onClick={()=>{
                        document.getElementById('contact-form').scrollIntoView({behavior: "smooth"})
                    }}
                    onKeyDown={()=>{
                        document.getElementById('contact-form').scrollIntoView({behavior: "smooth"})
                    }}
                    tabIndex={0}
                    role="button">
                        &#8681; Lavoriamo assieme &#8681;
                    </div>
                }
                rightChild={
                    <div className="flex flex-col text-white portrait:text-left text-right w-full">
                        <a
                        href="mailto:info@techfriuli.it"
                        className="lg:self-end text-4xl text-emerald-300 hover:text-emerald-300 font-bold pb-4">
                            info@techfriuli.it
                        </a>
                        <a
                        className="lg:self-end font-bold text-emerald-300 hover:text-emerald-300"
                        href="https://maps.app.goo.gl/zaxsupShoe5jRU339"
                        target="blank_">
                            Dove siamo &#8599;
                        </a>
                        <p>Via Feletto, 58</p>
                        <p>Udine 33100, UD</p>
                    </div>
                }
                darkbg />
            </div>

            <section title={id_where} id={id_where} >
                <div className="group w-full relative">
                    <GoogleMap className="w-full h-[80vh]" />
                    <div data-sal="flip-down" data-sal-duration="1000"
                    className={`hidden absolute w-3/5 md:w-2/5 font-bold -top-10 md:-top-20 rounded-xl
                    right-0 bg-secondary-600 text-white h-20 md:h-40 text-3xl md:flex flex-col items-center 
                    justify-center shadow-2xl group-hover:-skew-x-3 duration-500 text-center border border-secondary-500
                    `}>
                        Come Raggiungerci
                        <p className="hidden lg:block !pb-0 pt-3 animate-pulse">
                            &#8681;&#8681;
                        </p>
                    </div>
                </div>
            </section>

            <section id={id_info} title={id_info} className={section_style}>
                <div className={`flex flex-col xl:flex-row justify-between w-full text-2xl `}>
                    
                    {/* <div data-sal="zoom-in" data-sal-duration="900"
                    className="flex flex-col bg-white hover:scale-105 px-5 py-10 xl:px-10 xl:py-16 
                    shadow-xl duration-300 w-full xl:w-[48%] items-center justify-center hover:rounded-3xl">
                        <p className="font-sans font-bold leading-tight uppercase text-3xl 
                        md:text-4xl lg:text-5xl xxl:text-6xl pb-3 text-primary-500">
                            Orari
                        </p>
                        <LineDivider className="text-primary-700" />
                        <p>Dal
                            <span className="font-bold"> lunedì </span>
                                al
                            <span className="font-bold"> venerdì </span>
                        </p>
                        <p>09:00 - 13.00</p>
                        <p>14:00 - 18:00</p>
                    </div> */}

                    <div data-sal="zoom-in" data-sal-duration="900"
                    className="flex flex-col bg-white hover:scale-105 mt-7 xl:mt-0 p-5 xl:p-10 
                    shadow-xl duration-300 text-xl w-full items-center justify-center hover:rounded-3xl">
                        <p className="font-sans font-bold leading-tight uppercase text-3xl 
                        md:text-4xl lg:text-5xl xxl:text-6xl pb-3 text-primary-500">
                            Contatti
                        </p>
                        <LineDivider className="text-primary-700" />
                        <p><b>Amministrazione:</b> <a href="mailto:amministrazione@techfriuli.it">amministrazione@techfriuli.it</a></p>
                        <p><b>Assistenza:</b> <a href="mailto:assistenza@techfriuli.it">assistenza@techfriuli.it</a></p>
                        <p><b>Info:</b> <a href="mailto:info@techfriuli.it">info@techfriuli.it</a></p>
                        <p><b>PEC:</b> <a href="mailto:techfriuli@pec.it">techfriuli@pec.it</a></p>
                    </div>
                </div>
            </section>

            <div className="w-full flex flex-col items-center justify-center pt-24">
                <ContactForm className="mx-0" />
            </div>
        </>
    )
}