import React from "react"

/**
 * @title LineDivider
 * @param {String} className 
 * @returns {React.Component} Html p element
 * @description Divider customizzabile tramite classi CSS (tailwind)
 */
const lineDivider = ({className}) => {
    return(
        <p className={className + " pb-8"}>
            &#9620;&#9620;&#9620;&#9620;&#9620;&#9620;
        </p>
    )
}

export default lineDivider