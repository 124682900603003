import { useContext } from 'react'
import { ToastContext } from './toast-context'

/**
 * @title useToast Hook
 * @returns Toast Context
 * @description Ritorna il contesto per utilizzare l'hook useToast() creato
 * tramite l'hook reacteContext di React e utilizzato da useContext().
 */

export const useToast = () => useContext(ToastContext)