import React from 'react'

/**
 * @title GoogleMap
 * @param {String} className 
 * @returns {React.Component} Iframe element
 * @description Componente React che esporta un iFrame con la mappa di Google Maps con le indicazioni della posizione 
 * dell'azienda. Al momento è stato impostato un iframe ma in futuro si può integrare anche il modulo
 * google-map-react se fosse necessario.
 */


export default function GoogleMap({className}) {
    
    const isClient = typeof window !== 'undefined'

    return (
        isClient && (
            <iframe data-sal="slide-up" data-sal-duration="600"
            title="Google Map Techfriuli"
            alt="Google Map Techfriuli"
            className={className}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3516.976266898237!2d13.230402076983067!3d46.081872671090885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477a35645f012b2b%3A0xfbf14576f21a6fd1!2sVia%20Feletto%2C%2058%2C%2033100%20Udine%20UD!5e1!3m2!1sit!2sit!4v1714749618730!5m2!1sit!2sit"
            width="100%" height="100%" frameBorder="0" marginWidth="0" marginHeight="0" scrolling="no" data-mce-fragment="1">
            </iframe>
        )
    )
}